var GDPR_CONST = {
    name: 'gdpr',
    key: 'webrectrix.gdpr',
    text: 'We collect and use identifiers such as cookies to make it ' +
        'easier for you to use our website and give you a better experience. ' +
        'By using this website, you consent to Organogenesis using website ' +
        'identifiers, including cookies, for these purposes. To read our ' +
        'privacy policy, click '
};

// Component Declaration
var GDPR = function(element) {
    this.isGDPRAccepted = document.cookie.indexOf('gdrp=') > -1
    this.$element = $(element);

    this.create();
    this.init();
};

GDPR.prototype = {
    create: function() {
        if (!this.isGDPRAccepted) {
            this.$button = $('<button><span>&times;</span></button>');

            this.$link = $('<a href="#">here</a>');

            var $text = $('<p></p>')
                .append(GDPR_CONST.text)
                .append(this.$link);

            this.$wrapper = $('<div class="component component-gdpr"></div>')
                .append($text)
                .append(this.$button);
            this.$wrapper.hide();

            this.$container = $('<div class="container gdpr fullwidth"></div>')
                .append(this.$wrapper);
            this.$container.prependTo('body');

            this.$wrapper.slideDown('slow');
        }
    },
    init: function() {
        if (!this.isGDPRAccepted) {
            this.$button.on('click', this.handleButton.bind(this));
            this.$link.on('click', this.handleLinkClick.bind(this));
            this.$container.on('gdrp:confirm', this.handleConfirm.bind(this));
            this.$container.on('gdrp:hide', this.handleHide.bind(this));
        }
    },
    handleButton: function() {
        this.$container.trigger('gdrp:confirm');
        this.$container.trigger('gdrp:hide');
    },
    handleLinkClick: function() {
        this.$container.trigger('gdrp:confirm');
        window.location.href = '/privacy.html';
        return false;
    },
    handleConfirm: function() {
        var expireDate = new Date();
        //set "expstring" to either future or past date, to set or delete cookie, respectively
        expireDate.setDate(expireDate.getDate() + (40 * 365));
        document.cookie = "gdrp=true; expires=" + expireDate.toGMTString() + "; path=/";
    },
    handleHide: function() {
        this.$container.slideUp('slow');
    }
};

function _jQueryInterfaceGDPR(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(GDPR_CONST.key);

        if (!data) {
            data = new GDPR(this, config);
            $element.data(GDPR_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
};

$.fn[GDPR_CONST.name] = _jQueryInterfaceGDPR;
$.fn[GDPR_CONST.name].Constructor = GDPR;
$.fn[GDPR_CONST.name].noConflict = () => {
    $.fn[GDPR_CONST.name] = $.fn[GDPR_CONST.name];
    return _jQueryInterfaceGDPR;
};

$(function() {
    $('.gdpr-enabled').gdpr();
});
