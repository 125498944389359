$(function() {
    if ($('.component-accordion .accordion-head').length) {
        $('.component-accordion .accordion-head').on('click', showItem);
        $(window).on('resize', function() {
            $('.component-accordion .accordion-body').removeAttr('style');
            $('.component-accordion .accordion-head').removeClass('active');
        });
        function showItem(e){
            if ($(window).width() >= 768) {
                // Should prevent the collapsible and default anchor linking
                // behavior for screen sizes equal or larger than 768px.
                e.preventDefault();
                e.stopPropagation();
                return;
            }
            $('.component-accordion .accordion-head').not($(this)).removeClass('active');
            $('.component-accordion .accordion-body').not($(this).next()).slideUp(300);
            $(this).next().slideToggle(500);
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');
            } else {
              $(this).addClass('active');
            }
        }
    }
});