$(function () {
	const Plugin = videojs.getPlugin('plugin');

	class ChaptersPlugin extends Plugin {
		constructor(player, options) {
			super(player, options);

			this.player = player;

			player.on('loadedmetadata', this._onLoadMetadata.bind(this));
			player.on('resize', this._onResize.bind(this));
			player.on('playerresize', this._onResize.bind(this));
		}

		_onResize() {
			var parentHeight = $(this.player.el()).outerHeight();
			var controlHeight = $(this.player.controlBar.el()).height();
			if (parentHeight && controlHeight && parentHeight > controlHeight) {
				$(this.player.controlBar.chaptersButton.menu.contentEl()).css('max-height', (parentHeight - (controlHeight + 10)) + 'px');
			}
		}

		_onLoadMetadata() {
			let cuesAra = [];
			let chapters = [];
			let chapterTT = Array.from(this.player.textTracks()).filter(tt => tt.kind === "chapters");

			if (chapterTT[0]) {
				cuesAra = chapterTT[0].cues;

				for (let i = 0; i < cuesAra.length; i++) {
					chapters.push({
						start: cuesAra[i].startTime,
						text: cuesAra[i].text
					});
				}
				this._addMarkers(chapters);
			}
		}

		_addMarkers(chapters) {
			const videoDuration = this.player.duration();
			const playheadWell = document.querySelector(".vjs-progress-control.vjs-control");
			const markers = [];
			const $items = $('.vjs-chapters-button .vjs-menu .vjs-menu-content .vjs-menu-item');

			chapters.forEach((chapter, i) => {
				const marker = document.createElement("div");
				marker.className = "vjs-marker";
				marker.id = 'cp' + i;
				marker.dataset.id = i;
				marker.style.left = `${(chapter.start / videoDuration * 100)}%`;
				playheadWell.appendChild(marker);

				const text = document.createElement('span');
				text.innerHTML = chapter.text;
				marker.appendChild(text);

				markers.push(marker);

				$items.eq(i).data('id', i);
				$(marker).on({
					mouseover: function () {
						$items.removeClass('vjs-marker-hovered');
						$items.eq(this.dataset.id).addClass('vjs-marker-hovered');
					},
					mouseleave: function () {
						$items.eq(this.dataset.id).removeClass('vjs-marker-hovered');
					}
				});
			});

			$items.on({
				mouseover: function () {
					$(markers[$(this).data('id')]).addClass('vjs-marker-hovered');
				},
				mouseleave: function () {
					$(markers[$(this).data('id')]).removeClass('vjs-marker-hovered');
				}
			});
		}
	}

	videojs.registerPlugin('chapters', ChaptersPlugin);

	// General video player setup function
	function setupVideoPlayer(playerId, eventName, markers) {
		if ($('#' + playerId).length) {
			var player = videojs(playerId, {
				controlBar: {
					pictureInPictureToggle: false,
					fullscreenToggle: true
				}
			});
	
			player.on('play', function() {
				dataLayer.push({'event': eventName, 'value': 'PLAY'});
			});
	
			player.on('timeupdate', function() {
				var percentPlayed = Math.floor(this.currentTime() * 100 / this.duration()).toString();
				if (markers[percentPlayed]) {
					markers[percentPlayed] = false;
					dataLayer.push({'event': eventName, 'value': percentPlayed + '%'});
				}
			});
	
			player.on('pause', function() {
				dataLayer.push({'event': eventName, 'value': 'STOP'});
			});
	
			player.on('ended', function() {
				dataLayer.push({'event': eventName, 'value': 'COMPLETE'});
			});
		}
	}

	setupVideoPlayer('video-using-living-cell-technology', 'Using Living Cell Technology', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-intro-ops', 'Intro OPS', {
		'10': true, '25': true, '50': true, '75': true, '90': true
	});

	setupVideoPlayer('video-puraply-post-surgical', 'PuraPly Post-Surgical', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-puraplymz-dr-dev-testimonial', 'PuraPly MZ Dr Dev Testimonial', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-puraplysx-product', 'PuraPly SX', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-nushield-product', 'NuShield', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-dr-ng-webinar', 'NuShield Dr Ng Foot Innovate Webinar', {
		'10': true, '25': true, '50': true, '75': true
	});

	setupVideoPlayer('video-protecting-nerves-tendons-wounds-webinar', 'Protecting Nerves, Tendons & Wounds Webinar', {
		'10': true, '25': true, '50': true, '75': true
	});

	var Button = videojs.getComponent('Button');

	class SkipButton extends Button {
	constructor(player, options) {
		super(player, options);
		this.player = player;
		this.player.on('timeupdate', this._onPlayerTimeUpdate.bind(this));
		this.skipTime = options.skipTime || 0;
	}

	createEl(tag, props = {}, attributes = {}) {
		props.innerHTML = '<span>Skip intro</span><i class="icon font-weight-bold icon-angle-double-right"></i>';
		props.className = 'video-instructions-skip-button';
		attributes.type = 'button';
		return super.createEl('button', props, attributes);
	}

	handleClick() {
		this.player.currentTime(this.skipTime);
	}

	_onPlayerTimeUpdate() {
		var time = this.player.currentTime();
		if (time > 1 && time < this.skipTime - 1) {
		this.addClass('visible');
		this.enable();
		}
		if (time > this.skipTime) {
		this.removeClass('visible');
		this.disable();
		}
	}
	}

	// To register the new component with Video.js so it can be used:
	videojs.registerComponent('SkipButton', SkipButton);
});