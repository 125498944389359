var HEADER_VIDEO_CONST = {
    name: 'jumbotron',
    key: 'webrectrix.jumbotron'
};

// Component Declaration
var Jumbotron = function(element, variant) {
    this.variant = variant;
    this.$video = $('video', element);
    this.$callout = $('.callout', element);

    this.init();
};

Jumbotron.prototype = {
    init: function() {
        if (this.variant === 'home') {
            this.$video.on('ended', this.handleStopEvent.bind(this));
        }
    },
    handleStopEvent: function() {
        this.$callout.show().addClass('fade-in');
    }
};

function _jQueryInterfaceFormRegistration(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(HEADER_VIDEO_CONST.key);

        if (!data) {
            data = new Jumbotron(this, config);
            $element.data(HEADER_VIDEO_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
};

$.fn[HEADER_VIDEO_CONST.name] = _jQueryInterfaceFormRegistration;
$.fn[HEADER_VIDEO_CONST.name].Constructor = Jumbotron;
$.fn[HEADER_VIDEO_CONST.name].noConflict = () => {
    $.fn[HEADER_VIDEO_CONST.name] = $.fn[HEADER_VIDEO_CONST.name];
    return _jQueryInterfaceFormRegistration;
};

$(function() {
    $('.component-jumbotron').jumbotron('home');
});