$(function () {
    if ($('.component-header').length) {
        // Define animation
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        var keyFramAnimationSupported = document.body.style.animationName !== undefined && !isIE11;
        var isMobile = $(window).width() < 768;

        var $nav = getContainer();
        var $rootmenu = getRootMenu();
        var $submenu = getSubMenu();
        var $toggle = getToggle();
        var $background = getBackground();
        var rootHeight = $rootmenu.height();

        adjustClasses();
        // Copy util navigation items
        var $items = $('.util-nav-item', 'header').clone();
        var $wrapper = $('<ul></ul>').append($items);
        $('.util-nav', 'header').append($wrapper);
        $('#nav > ul > li:not(.util-nav-item)', 'header').last().addClass('main-nav-last-item');
        adjustEventListeners();

        $(window).on('resize', function(){
            isMobile = $(window).width() < 768;
            if (!$('.header-background').hasClass('active')) {
                $toggle.trigger('clear');
                $submenu.trigger('clear');
                $rootmenu.trigger('clear');
                $nav.trigger('clear');
                $background.trigger('clear');
                rootHeight = $rootmenu.height();
            }
        });

        function getBackground() {
            $background = $('<div class="header-background"></div>');
            $background.on('background:toggle', function() {
                $(this).toggleClass('active');
            });
            $background.on('clear', function() {
                $(this).removeClass('active');
            });
            $background.on('click', function() {
                $toggle.trigger('menu:close');
                return false;
            });
            $('body').append($background);
            return $background;
        }

        function getToggle() {
            var $toggle = $('#nav-toggle', 'header');
            $toggle.on('click', function() {
                $(this).toggleClass('active');
                if ($(this).hasClass('active')) {
                    $(this).trigger('menu:open');
                } else {
                    $(this).trigger('menu:close');
                }
            });
            $toggle.on('clear', function() {
                $(this).removeClass('active');
            });
            $toggle.on('menu:close', function() {
                $background.trigger('background:toggle');
                $nav.trigger('update:height', 0);
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                }
            });
            $toggle.on('menu:open', function() {
                $rootmenu.trigger('clear');
                $submenu.trigger('clear');
                $background.trigger('background:toggle');
                $nav.trigger('update:height', rootHeight);
            });
            return $toggle;
        }

        function getRootMenu() {
            if (keyFramAnimationSupported) {
                $.keyframe.define([{
                    name: 'rootmenu-show',
                    '0%': { left: '-100%', top: '-100%', opacity: 0 },
                    '50%': { opacity: .3 },
                    '100%': { left: 0, top: 0, opacity: 1 }
                }, {
                    name: 'rootmenu-hide',
                    '0%': { left: 0, top: 0, opacity: 1 },
                    '50%': { opacity: .3 },
                    '100%': { left: '-100%', top: '-100%', opacity: 0 }
                }]);
            }
            var $rootmenu = $('#nav > ul', 'header');
            $rootmenu.on('show', function() {
                $(this).css('z-index', 3);
                if (keyFramAnimationSupported)
                    $(this).playKeyframe({ name: 'rootmenu-show', duration: '0.5s' });
                else
                    $(this).animate({ left: 0, bottom: 0, opacity: 1 }, { duration: 500, queue: false });
            });
            $rootmenu.on('hide', function() {
                $(this).css('z-index', 2);
                if (keyFramAnimationSupported)
                    $(this).playKeyframe({ name: 'rootmenu-hide', duration: '0.5s' });
                else
                    $(this).animate({ left: '-100%', bottom: '-100%', opacity: 0 }, { duration: 500, queue: false });
            });
            $rootmenu.on('clear', function() {
                $(this).removeAttr('style');
            });
            return $rootmenu;
        }

        function getContainer() {
            var $nav = $('#nav', 'header');
            $nav.on('update:height', function(e, height) {
                $(this).animate({ height: height + 'px' }, { duration: 500, queue: false });
            });
            $nav.on('clear', function() {
                $(this).removeAttr('style');
            })
            return $nav;
        }

        function getSubMenu() {
            if (keyFramAnimationSupported) {
                $.keyframe.define([{
                    name: 'submenu-hide',
                    '0%': { right: '0', top: '0' },
                    '50%': { opacity: .3 },
                    '100%': { right: '-100%', top: '-100%', opacity: 0 }
                }, {
                    name: 'submenu-show',
                    '0%': { right: '-100%', top: '-100%' },
                    '50%': { opacity: .3 },
                    '100%': { right: 0, top: 0, opacity: 1 }
                }]);
            }
            var $container = $('<div class="submenu-container"></div>');
            var $title = $('<div class="submenu-title"></div>');
            var $button = $('<button type="button"><span class="icon-arrow"></span></button>');
            var $submenu = $('<div id="submenu"></div>');
            $submenu.append($button);
            $submenu.append($title);
            $submenu.append($container);
            $nav.append($submenu);

            $submenu.on('hide', function() {
                $submenu.css('z-index', 2);
                if (keyFramAnimationSupported)
                    $(this).playKeyframe({ name: 'submenu-hide', duration: '0.5s' });
                else
                    $(this).animate({ right: '-100%', top: '-100%', opacity: 0 }, { duration: 500, queue: false });
            });

            $submenu.on('show', function(e, title, content) {
                $title.html(title);
                $container.html(content);
                $submenu.css('z-index', 3);
                if (keyFramAnimationSupported) {
                $submenu.playKeyframe({ name: 'submenu-show', duration: '0.5s' });
                } else {
                $submenu.animate({ right: 0, top: 0, opacity: 1 }, { duration: 500, queue: false });
                }
            });

            $submenu.on('clear', function() {
                $(this).removeAttr('style');
                $title.html('');
                $container.html('');
            });

            $button.on('click', function() {
                $submenu.trigger('hide');
                $rootmenu.trigger('show');
                $nav.trigger('update:height', rootHeight);
            });

            return $submenu;
        }

        function adjustClasses() {
            $rootmenu.children('li').each(function() {
                if ($(this).has('ul').length) {
                    $(this).addClass('with-submenu');
                    $(this).append('<span class="icon-arrow"></span>');
                    $(this).children('ul').children('li').each(function() {
                        if ($(this).has('ul').length) {
                            $(this).addClass('with-submenu');
                            $(this).append('<span class="icon-arrow"></span>');
                        }
                    })
                }
            });
        }

        function adjustEventListeners() {
            $rootmenu.children('li.with-submenu').each(function() {
                $(this).on('click', function(e) {
                    if (e.target !== this && !isChildSpan(this, e.target)) return;
                    if (!isMobile) return false;
                    var $submenuContent = $(this).children('ul').clone();
                    var title = $(this).children('a').html();
                    $submenu.trigger('show', [title, $submenuContent]);
                    $rootmenu.trigger('hide');
                    var submenuHeight = $submenu.height();
                    $nav.trigger('update:height', submenuHeight);

                    $submenuContent.children('li.with-submenu').each(function() {
                        var submenu = $(this).children('ul');
                        $(this).on('click', function(e) {
                            if (e.target !== this && !isChildSpan(this, e.target)) return;
                            if (!isMobile) return false;
                            submenu.toggleClass('active');
                            $(this).toggleClass('active');
                            var dropdownHeight = $(this).children('ul').height();
                            var collapseHeight = 35;
                            var navHeight = submenuHeight;
                            if ($(this).hasClass('active')) {
                                collapseHeight += dropdownHeight;
                            }
                            $submenuContent.children('li.with-submenu.active').each(function() {
                                var dropdownHeight = $(this).children('ul').height();
                                navHeight += dropdownHeight;
                            });
                            $nav.trigger('update:height', navHeight);
                            $(this).animate({ height: collapseHeight + 'px'}, 500);
                        });
                    });
                });
            });

            function isChildSpan(parent, child) {
                return $(parent).has(child).length > 0 && $(child).is('span');
            }
        }
    }
});