$(function () {
    if ($('.component-leaders').length) {
        console.log('--> load leaders');
        windowWidthHandler();
        $( window ).resize(windowWidthHandler);
        function windowWidthHandler() {
            if ($(window).width() <= '767') {
                $('.leader-item').off('click').on('click', showInfoMobile);
                $('.mobile-show-list').off('click').on('click', hideInfoMobile);
                checkWindowSize();
            } else if ($(window).width() > '767') {
                $('.leader-item').off('click');
                $('.mobile-show-list').off('click');
                $('.component-leaders').off('click', '.morelink');
                hideInfoMobile();
                $('.leader-item .leader-item-card').off('click').on('click', showInfoDesktop);
            }
        }
        function checkWindowSize() {

            $('.leader-item-info p').addClass('more');

            var showChar = 200;
            var moretext = "Show more";
            var lesstext = "Show less";

            $('.more').not('.more-inited').each(function() {
                var content = $(this).html();

                if(content.length > showChar) {
                    var length = content.length - showChar;
                    var c = content.substr(0, showChar);
                    var h = content.substr(showChar, length);

                    var html = c + '<span class="moreellipses">... ' +
                    '&nbsp;</span><span class="morecontent"><span>' + h +
                    '</span>&nbsp;&nbsp;<div class="morelink icon-arrow">' + moretext +
                    '</div></span>';

                    $(this).html(html);
                    $(this).addClass('more-inited');
                }
            });
            $('.component-leaders').off('click', '.morelink').on('click', '.morelink', function(){
                if($(this).hasClass("less")) {
                    $(this).html(moretext);
                } else {
                    $(this).html(lesstext);
                }
                $(this).toggleClass('less');
                $(this).parent().prev().toggle();
                $(this).prev().toggle();
                return false;
            });
        }

        function hideInfoMobile() {
            $('.leader-item').show();
            $('.leader-item').removeAttr('id');
            $('.mobile-show-list').removeClass('active');
            $('.leader-item-info p').removeClass('more');
            $('.container-jumbotron').show();
            $('.leader-item-info').removeAttr('style');
            $('.leader-item-info').hide();
        }

        function showInfoMobile() {
            $('.leader-item').hide();
            $(this).removeAttr('id');
            $(this).attr('id', 'mobile-card-active');
            $('.mobile-show-list').addClass('active');
            $('.container-jumbotron').hide();
            $('#mobile-card-active').show();
            $('.leader-item-info').show();
        }

        function showInfoDesktop(){
            $('.leader-item .leader-item-info').not($(this).next()).fadeOut(200);
            $(this).next().fadeToggle(300);
            var startCoord = $('.leader-item').offset().left;
            var currentCoord = $(this).offset().left;
            var index = $('.leader-item .leader-item-card').index(this) + 1;
            var width = $(this).outerWidth(true);
            var triangleOffset = ((width - 150) / 2);

            if ('1200' < $(window).width() < '1079') {
                var place = index % 3;
                switch(place) {
                    case 1:
                        $('.leader-item-info').offset({left: startCoord + 40});
                        $('.triangle-wrapper').offset({left: startCoord + triangleOffset });
                        break;
                    case 2:
                        $('.leader-item-info').offset({left: startCoord + 40});
                        $('.triangle-wrapper').offset({left: currentCoord + triangleOffset });
                        break;
                    case 0:
                        $('.leader-item-info').offset({left: $('.container-leaders').width() -  $('.leader-item-info').width() - 10});
                        $('.triangle-wrapper').offset({left: currentCoord + triangleOffset });
                        break;
                }
            }

            if ($(window).width() > '1200') {
                var place = index % 4;
                switch(place) {
                    case 1:
                        $('.leader-item-info').offset({left: startCoord + 40});
                        $('.triangle-wrapper').offset({left: startCoord + triangleOffset });
                        break;
                    case 2:
                        $('.leader-item-info').offset({left: startCoord + 40});
                        $('.triangle-wrapper').offset({left: currentCoord + triangleOffset });
                        break;
                    case 3:
                        $('.leader-item-info').offset({left: $('.container-leaders').width() -  $('.leader-item-info').width() + 15});
                        $('.triangle-wrapper').offset({left: currentCoord + triangleOffset });
                        break;
                    case 0:
                        $('.leader-item-info').offset({left: $('.container-leaders').width() -  $('.leader-item-info').width() + 15});
                        $('.triangle-wrapper').offset({left: currentCoord + triangleOffset });
                        break;
                }
            }
            $('.close-info').on('click', function() {
                $('.leader-item-info').fadeOut(300);
            });
        }
    }
});