$(function () {
    if ($('.container-toast').length) {
        var height = $('.container-toast').outerHeight();
        $('.container-toast').css('bottom', height * -1);
        show();
        function show() {
            $('.container-toast').css('bottom', 0);
        }
        function hide() {
            $('.container-toast').css('bottom', height * -1);
        }
        $('.container-toast').find('.btn-close').on('click', function(e) {
            hide();
        })
        $(window).resize(function() {
            height = $('.container-toast').outerHeight();
        });
    }
});