var EXTERNAL_LINK_CONST = {
    name: 'external',
    key: 'webrectrix.external_link'
};

// Component Declaration
var ExternalLink = function(element) {
    this.$element = $(element);
    this.$modal = $('#externalModal');
    this.$button = $('.btn-primary', this.$modal);

    this.create();
    this.init();
};

ExternalLink.prototype = {
    create: function() {
        this.href = this.$element.prop('href');

        this.handleClickLink = this.handleClickLink.bind(this);
    },
    init: function() {
        this.$element.on('click', this.handleClickLink);
    },
    handleClickLink: function() {
        this.$button.prop('href', this.href);
        this.$modal.modal('show');
        return false;
    }
};

function _jQueryInterfaceExternalLink(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(EXTERNAL_LINK_CONST.key);

        if (!data) {
            data = new ExternalLink(this, config);
            $element.data(EXTERNAL_LINK_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
};

$.fn[EXTERNAL_LINK_CONST.name] = _jQueryInterfaceExternalLink;
$.fn[EXTERNAL_LINK_CONST.name].Constructor = ExternalLink;
$.fn[EXTERNAL_LINK_CONST.name].noConflict = () => {
    $.fn[EXTERNAL_LINK_CONST.name] = $.fn[EXTERNAL_LINK_CONST.name];
    return _jQueryInterfaceExternalLink;
};

$(function() {
    // $('a[href*="investors.organogenesis.com"], a[href*="careers.organogenesis.com"]').external();
    $('a[href*="careers.organogenesis.com"]').external();
});
