$(function(){
    $.fn.jTimeline = function (options) {

        var settings = $.extend({
            step: 225
        }, options);

        return this.each(function () {

            var $timeline = $(this),
                timelineWidth = $timeline.width(),
                $eventsList = $timeline.find('.jtimeline-events'),
                $events = $eventsList.find('.jtimeline-event'),
                width = $events.length * settings.step,
                initialX = 0;

            $eventsList.css('width', width + 'px');

            $timeline
                .prepend('<div class="jtimeline-scroll-left"><div class="jtimeline-arrow">&lsaquo;</div></div>')
                .append('<div class="jtimeline-scroll-right"><div class="jtimeline-arrow">&rsaquo;</div></div>');

            var $scrollLeft = $timeline.find('.jtimeline-scroll-left'),
                $scrollRight = $timeline.find('.jtimeline-scroll-right');

            $events.each(function (index) {
                var $event = $(this),
                    eventLabel = $event.html(),
                    isActive = $event.hasClass('is-active'),
                    position = settings.step * index;

                $event
                    .css('left', position + 'px')
                    .html('')
                    .append('<div class="jtimeline-event-bullet"></div>')
                    .append('<div class="jtimeline-event-label">' + eventLabel + '</div>');

                if (isActive) {
                    initialX = -position;
                }
            });

            var hidden = width - timelineWidth,
                minX = -hidden;
            if (initialX < minX) {
                initialX = minX;
            }

            $(window).on('resize', function () {
                var currentTimelineWidth = timelineWidth,
                    newTimelineWidth = $timeline.width();

                if (currentTimelineWidth !== newTimelineWidth) {
                    timelineWidth = newTimelineWidth;
                    hidden = width - timelineWidth;
                    minX = -hidden;

                    var transform = $eventsList.css('transform'),
                        currentX = transform === 'none' ? 0 : parseInt(transform.split(/[()]/)[1].split(', ')[4]);
                    if (currentX < minX) {
                        currentX = minX;
                        $eventsList.trigger('jtimeline:translate', [currentX]);
                    }
                }
            })

            $eventsList.on('jtimeline:translate', function (e, x) {
                if (typeof x === 'number') {
                    $eventsList.css('transform', 'translate(' + x + 'px, 0)');

                    $scrollLeft.removeClass('is-disabled');
                    $scrollRight.removeClass('is-disabled');

                    if (x === 0) {
                        $scrollLeft.addClass('is-disabled');
                    } else if (typeof minX === 'number' && x === minX) {
                        $scrollRight.addClass('is-disabled');
                    }
                }
            });

            $eventsList.trigger('jtimeline:translate', [initialX]);

            $scrollLeft.on('click', function (e) {
                e.preventDefault();

                var transform = $eventsList.css('transform'),
                    currentX = transform === 'none' ? 0 : parseInt(transform.split(/[()]/)[1].split(', ')[4]),
                    newX = currentX + settings.step;

                if (newX > 0) {
                    newX = 0;
                }

                $eventsList.trigger('jtimeline:translate', [newX]);
            });

            $scrollRight.on('click', function (e) {
                e.preventDefault();

                var transform = $eventsList.css('transform'),
                    currentX = transform === 'none' ? 0 : parseInt(transform.split(/[()]/)[1].split(', ')[4]),
                    newX = currentX - settings.step;

                if (newX < minX) {
                    newX = minX;
                }

                $eventsList.trigger('jtimeline:translate', [newX]);
            });
        });

    };
    $('.component-our-story-timeline').jTimeline();
  });