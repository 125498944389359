var EXTERNAL_MODAL_CONST = {
    name: 'externalModal',
    key: 'webrectrix.external_modal'
};

// Component Declaration
var ExternalModal = function(element) {
    this.$element = $(element);
    this.$button = $('.btn-primary', this.$element);

    this.create();
    this.init();
};

ExternalModal.prototype = {
    create: function() {
        this.handleClick = this.handleClick.bind(this);
    },
    init: function() {
        this.$button.on('click', this.handleClick);
    },
    handleClick: function() {
        var href = this.$button.prop('href');
        this.$element.modal('hide');
        window.open(href, '_blank');
        return false;
    }
};

function _jQueryInterfaceExternalModal(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(EXTERNAL_MODAL_CONST.key);

        if (!data) {
            data = new ExternalModal(this, config);
            $element.data(EXTERNAL_MODAL_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
};

$.fn[EXTERNAL_MODAL_CONST.name] = _jQueryInterfaceExternalModal;
$.fn[EXTERNAL_MODAL_CONST.name].Constructor = ExternalModal;
$.fn[EXTERNAL_MODAL_CONST.name].noConflict = () => {
    $.fn[EXTERNAL_MODAL_CONST.name] = $.fn[EXTERNAL_MODAL_CONST.name];
    return _jQueryInterfaceExternalModal;
};

$(function() {
    $('#externalModal').externalModal();
});