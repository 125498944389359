$(function() {
    if ($('#carousel-news-events').length) {
        $('#carousel-news-events').carousel({
            interval: false
        });
    }
    if ($('#news-events-list').length) {
        $('#news-events-list').each(function(i) {
            var self = $(this);
            var options = {
                filters: {
                    news: 'all', // all | upcoming | past | none
                    events: 'all',  // all | upcoming | past | none
                    search: '',
                    product: [],
                    corporate: []
                },
                offset: 0,
                limit: 3,
                total: 0
            };
            var templates = {
                home: function(data) {
                    var $body = $('<div class="data-list-item"></div>');
                    var $left = $('<div></div>');
                    var $right = $('<div></div>');
                    $body.append($left);
                    $body.append($right);

                    if (data.date) {
                        var $date = $('<div class="date"></div>');
                        $date.append(data.date);
                        $left.append($date);

                        if (data.time) {
                            var $time = $('<div class="time"></div>');
                            $time.append(data.time);
                            $left.append($time);
                        }
                    }

                    if (data.location) {
                        var $location = $('<div class="location"></div>');
                        $location.append(data.location);
                        $left.append($location);
                    }

                    // Full post without individual page
                    if (!!data.fullPost) {
                        var $title = $('<div class="name"></div>');
                        $title.html(data.title)
                        $right.append($title);

                        var $fullPost = $('<div class="description"></div>');
                        $fullPost.append(data.fullPost);
                        $right.append($fullPost);

                    } else {
                        var $title = $('<div class="name"></div>');
                        var $link = $('<a></a>');

                        if (data.time) {
                            $link.html(data.location + ' - "' + data.title + '"');
                        } else {
                            $link.html(data.title);
                        }
                        $link.attr('href', data.link);
                        if (data.link.startsWith('http')) {
                            $link.attr('target', '_blank');
                        }
                        $title.append($link);
                        $right.append($title);

                        if (data.description) {
                            var $description = $('<div class="description"></div>');
                            $description.append(data.description);
                            $right.append($description);
                        }

                        var $button = $('<a class="read-more">Read More</a>');
                        $button.attr('href', data.link);
                        if (data.link.startsWith('http')) {
                            $button.attr('target', '_blank');
                        }
                        $right.append($button);
                    }
                    return $body;
                },
                page: function(data) {
                    var $body = $('<div class="data-list-item"></div>');
                    if (data.picture) {
                        var $image = $('<img class="preview">');
                        $image.attr('src', data.picture);
                        $body.append($image);
                    }
                    var $header = $('<div class="name"></div>');
                    var $link = $('<a></a>');
                    var $date = $('<div class="date"></div>')
                    if (data.time) {
                        $link.html(data.location + ' - "' + data.title + '"');
                    } else {
                        $link.html(data.title);
                    }
                    $link.attr('href', data.link);
                    if (data.link.startsWith('http')) {
                        $link.attr('target', '_blank');
                    }
                    $date.html(data.date);
                    if (data.time) {
                        var $time = $('<span class="time"></span>');
                        $time.append(' / ' + data.time);
                        $date.append($time);
                    }
                    $header.append($date);
                    $header.append($link);

                    // Bottom part of the post
                    var $footer = $('<div class="footer"></div>');

                    // Add tags
                    if (data.tags && data.tags.length) {
                        var $tags = $('<div class="tags"></div>');
                        $.each(data.tags, function(ti, tag) {
                            var $tag = $('<span></span>');
                            $tag.html(tag);
                            $tags.append($tag);
                        });
                        $footer.append($tags);
                    }

                    // Add Read More link
                    if (!data.fullPost) {
                        var $cta = $('<a class="button btn-blue read-more">Read More&nbsp;&raquo;</a>');
                        $cta.attr('href', data.link);
                        if (data.link.startsWith('http')) {
                            $cta.attr('target', '_blank');
                        }
                    }
                    $footer.append($cta);

                    // Put it all together
                    var $content = $('<div class="content"></div>');
                    $content.append($header);
                    $content.append($footer);

                    $body.append($content);

                    return $body;
                }
            };
    
            var $container = container();
            var $component = component();
            var $filters = filters();
            var $button = button();
    
            $(document).ready(function() {
                if (!$filters.length) {
                    $container.empty();
                    $component.trigger('fetch');
                }
            });
    
            function button() {
                var $button = self.find('.load-more', '#news-events-list');
                $button.children('button').on('click', function(e) {
                    if (e.target !== this ) return;
                    options.offset += options.limit;
                    $component.trigger('fetch');
                    return false;
                });
                $button.on('switch:visability', function() {
                    if (options.total > options.offset + options.limit) {
                        $button.removeClass('hidden');
                    } else {
                        $button.addClass('hidden');
                    }
                });
                return $button;
            }
    
            function filters() {
                var $filters = $('.component-news-events-filters', '#news-events-list');
                $filters.on('update:filters', function(e, filters) {
                    options.filters = filters;
                    options.offset = 0;
                    $container.empty();
                    $component.trigger('fetch');
                });
                return $filters;
            }
    
            function container() {
                var $container = self.find('.news-events-list');
                if (!!$container.data('filters')) {
                    var filterBy = $container.data('filters').split('|');
                    if (filterBy.length > 0) {
                        filterBy.forEach(function(f) {
                            var subset = f.split(':');
                            options.filters[subset[0]] = (subset.length > 1) ? subset[1] : 'all';
                        });
                    }
                }
                var templateName = $container.attr('data-template');
                var render = templates[templateName];
                if (!render) render = templates['page'];
                $container.on('render', function(e, items) {
                    $.each(items, function(index, item) {
                        var $item = render(item);
                        $container.append($item);
                    })
                });
                return $container;
            }
    
            function component() {
                var $component = self;
                $component.on('fetch', function() {
                    var query = {
                        action: 'ogn_get_filtered_news_and_events'
                    };
                    if (!$component.is('[infinite]')) {
                        query.offset = options.offset;
                        query.limit = options.limit;
                    }
                    var categories = [];
                    if (options.filters.news != 'none') categories.push('news');
                    if (options.filters.events != 'none') categories.push('events');
                    query.category = categories.join(',');
    
                    function normalizeTags(tags) {
                        return $(tags)
                            .map(function(){
                                return this.value;
                            })
                            .get()
                            .join(',');
                    }
    
                    if (options.filters.product.length) {
                        var products = normalizeTags(options.filters.product);
                        query.product_tag = products;
                    }
    
                    if (options.filters.corporate.length) {
                        var corporate = normalizeTags(options.filters.corporate);
                        query.corporate_tag = corporate;
                    }
    
                    if (options.filters.search.length) query.search = options.filters.search;
    
                    // Make sure browsers do not cache requests
                    query.t = new Date().getTime();
    
                    $.get({
                        url: '/news-events/data.json',
                        data: query,
                        success: function(response) {
    
                            // Enable these lines once backend is hooked up
                            // options.total = response.total;
                            // $button.trigger('switch:visibility');
    
                            // START Front-end filtering that should be removed once backend is hooked up
                            var data = response.data;
    
                            // Filter by search query
                            var searchQuery = options.filters.search.toLowerCase();
                            if (searchQuery !== '')
                                data = data.filter(function(article) {
                                    return (
                                        article.title.toLowerCase().search(searchQuery) >= 0 ||
                                        article.description.toLowerCase().search(searchQuery) >= 0 ||
                                        article.date.toLowerCase().search(searchQuery) >= 0 ||
                                        article.location.toLowerCase().search(searchQuery) >= 0 ||
                                        article.tags.join(' ').toLowerCase().search(searchQuery) >= 0
                                    );
                                });
                            
                            var now = new Date();
                            var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    
                            data = data.filter(function(a) {
                                if (a.tags.indexOf('News') > -1 && options.filters.news != 'none') {
                                    if (options.filters.news == 'all') return true;
                                    
                                    if (options.filters.news == 'upcoming')
                                        return Date.parse(a.date) >= tomorrow.getTime();
                                    else
                                        return Date.parse(a.date) < tomorrow.getTime();
                                }
    
                                if (a.tags.indexOf('Events') > -1 && options.filters.events != 'none') {
                                    if (options.filters.events == 'all') return true;
                                    
                                    if (options.filters.events == 'upcoming')
                                        return Date.parse(a.date) >= tomorrow.getTime();
                                    else
                                        return Date.parse(a.date) < tomorrow.getTime();
                                }
                            });
    
                            if (options.filters.news === 'none' && options.filters.events == 'upcoming')
                                data.sort(function(a, b) {
                                    var aDate = Date.parse(a.date);
                                    var bDate = Date.parse(b.date);
                                    return ((aDate < bDate) ? -1 : ((aDate > bDate) ? 1 : 0));
                                })
    
                            options.total = data.length;
                            if (data.length == 0 && self.data('hide'))
                                self.hide();
                            $button.trigger('switch:visability');
                            if (!$component.is('[infinite]')) {
                                data = data.slice(query.offset, query.offset + query.limit);
                            }
                            // END of Front-end filtering
    
                            $container.trigger('render', [data]);
                        },
                        dataType: 'json'
                    });
                });
                return $component;
            }
        });
    }
    if ($('.component-news-events-filters').length) {
        var isMobile = $(window).width() < 768;
        var filtersHeight = 0;
        var state = {
            news: 'all',
            events: 'all',
            search: '',
            product: [],
            corporate: []
        };
        var $component = $('.component-news-events-filters');
        var $selects = selects();
        var $variants = variants();
        var $filters = filters();
        var $bar = bar();

        $(document).ready(function() {
            $component.trigger('update:filters', [state]);
        });

        $(window).on('resize', function() {
            isMobile = $(window).width() < 768;
            $filters.trigger('calc:height');
            $filters.trigger('clear');
            $bar.trigger('clear');
            $selects.trigger('clear');
        });

        function variants() {
            var $variants = $('.applied-variants', '.component-news-events-filters');
            var $container = $variants.children('div').eq(0);
            var $clear = $variants.children('button').eq(0);
            var $firstSelect = $selects.filter('[data-name="product"]');
            var $secondSelect = $selects.filter('[data-name="corporate"]');
            function createItems(items, $select) {
                $.each(items, function(i, item) {
                    var $label = $('<div></div>');
                    $label.html(item.label);
                    var $button = $('<button type="button" class="icon-menu-close" />');
                    $button.on('click', function() {
                        $select.trigger('select:change', [item.value, item.label, true]);
                        return false;
                    });

                    var $item = $('<div class="variant-item"></div>');
                    $item.append($label);
                    $item.append($button);
                    $container.append($item);
                })
            }
            $variants.on('show', function() {
                var isContainerVisible = false;
                $container.empty();
                if (state.product.length) {
                    isContainerVisible = true;
                    createItems(state.product, $firstSelect);
                }
                if (state.corporate.length) {
                    isContainerVisible = true;
                    createItems(state.corporate, $secondSelect);
                }
                if (isContainerVisible) {
                    $variants.addClass('active');
                } else {
                    $variants.removeClass('active');
                }
            });
            $clear.on('click', function() {
                $selects.trigger('set:default');
                return false;
            });
            return $variants;
        }

        function filters() {
            var $filters = $('.filters', '.component-news-events-filters');
            $filters.on('calc:height', function() {
                filtersHeight = 0;
                $filters.children('div').each(function() {
                    filtersHeight += $(this).outerHeight(true);
                });
            });
            $filters.trigger('calc:height');
            $filters.on('update:height', function(e, height, complete) {
                if (!complete) {
                    complete = function () {}
                }
                $(this).animate({ height: height + 'px' }, { duration: 500, queue: false, complete: complete });
            });
            $filters.on('clear', function() {
                $(this).removeAttr('style');
            })
            $filters.on('set:switch', function(e, name, value) {
                state[name] = value;
                $component.trigger('update:filters', [state]);
            });
            $filters.on('set:selects', function(e, name, values, labels) {
                state[name] = values.reduce(function(results, value, index) {
                    var label = labels[index];
                    results.push({ label: label, value: value, index: index });
                    return results;
                }, []);
                $variants.trigger('show');
                $component.trigger('update:filters', [state]);
            });
            $filters.on('set:search', function(e, value) {
                state['search'] = value;
                $component.trigger('update:filters', [state]);
            });
            return $filters;
        }

        function bar() {
            var $bar = $('.filters-switch-bar', '.component-news-events-filters');
            $bar.children('h4, button, span').on('click', function(e) {
                if (e.target !== this) return;
                $bar.toggleClass('opened');
                if ($bar.hasClass('opened')) {
                    $filters.trigger('update:height', [filtersHeight]);
                } else {
                    $filters.trigger('update:height', [0, function() {
                        $selects.trigger('clear');
                    }]);
                }
                return false;
            });
            $bar.on('clear', function() {
                $bar.removeClass('opened');
            });
            return $bar;
        }

        function selects() {
            var $selects = $('div.select', '.component-news-events-filters .selects');
            $selects.on('change:height', function(e, height) {
                var sHeight, fHeight;
                if (height > 0) {
                    fHeight = height + filtersHeight;
                    sHeight = height + 40;

                } else {
                    fHeight = filtersHeight;
                    sHeight = 40;
                }
                $filters.trigger('update:height', [fHeight]);
                $(this).animate({ height: sHeight + 'px' }, { duration: 500, queue: false });
            })
            $selects.on('open', function() {
                var $button = $(this).children('button').eq(0);
                $button.addClass('opened');
                if (isMobile) {
                    var height = $(this).children('ul').eq(0).outerHeight(true);
                    $(this).trigger('change:height', [height]);
                }
            });
            $selects.on('close', function () {
                var $button = $(this).children('button').eq(0);
                $button.removeClass('opened');
                if (isMobile) {
                    $(this).trigger('change:height', [0]);
                }
            });
            return $selects;
        }

        // Clicked search button
        $('.search-input button', '.component-news-events-filters').on('click', function(e) {
            if (e.target !== this) return;
            var value = $('.search-input input', '.component-news-events-filters').eq(0).val();
            $filters.trigger('set:search', [value]);
        });
        // Press enter while on search field
        $('.search-input').keypress(function(e) {
            var keycode = (e.keyCode ? e.keyCode : e.which);
            if (keycode != '13') return;
            var value = $('.search-input input', '.component-news-events-filters').eq(0).val();
            $filters.trigger('set:search', [value]);
        });
        // Cleared out the search field
        $('.search-input').on('change paste keyup', function(e) {
            var value = $('.search-input input', '.component-news-events-filters').eq(0).val();
            if (value == '')
                $filters.trigger('set:search', [value]);
        })
        $('select', '.component-news-events-filters').on('value:update', function(e, values, labels) {
            var name = $(e.target).prop('name');
            $filters.trigger('set:selects', [name, values, labels]);
        });
        $('.filters-group.switches > div > label', '.component-news-events-filters').on('click', function(e) {
            if (e.target !== this) return;
            var $switch = $(this);
            var $input = $switch.children('input').eq(0);
            var name = $input.val();
            if ($input.prop('checked')) {
                $switch.removeClass('checked');
                $input.prop('checked', false);
            } else {
                $switch.addClass('checked');
                $input.prop('checked', true);
            }
            var stateDefault = {
                news: 'all',
                events: 'past',
            }
            $filters.trigger('set:switch', [name, $input.prop('checked') ? stateDefault[name] : 'none']);
            return false;
        });
    }
});